class Scroll {
  constructor() {
    this.init();
  }

  init() {
    jQuery('.js-scroll').on('click', (e) => {
      let target = jQuery(e.currentTarget).attr('href');
      if (target == '#') {
        target = 'html';
      }

      this.move(jQuery(target));
      // jQuery('.js-menu').removeClass('is-active');
      return false;
    });
  }

  move(target) {
    jQuery("html,body").animate({
      scrollTop: jQuery(target).offset().top - 120
    }, 500);
  }
}

export default Scroll;
