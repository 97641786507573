class Menu {
  constructor() {
    this.init();
  }

  init() {
    $('.js-menu-switch').on('click', (e) => {

      // let $html = jQuery('html');
      // if ($html.hasClass('is-menu')) {
      //   $html.removeClass('is-menu');
      // } else {
      //   $html.addClass('is-menu');
      // }
      if ($('.js-menu-body').hasClass('is-active')) {
        this.close();
      } else {
        $('.js-menu-body').addClass('is-active');
      }
      $('.js-menuWrapper').on('click',()=>{
        this.close();
      })
    });
    $('.main-menu-close').on('click', (e) => {
      this.close();
    });
  }

  close() {
    jQuery('.js-menu-body').removeClass('is-active');
  }
}
export default Menu;
